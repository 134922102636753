<template>
  <div class="d-flex flex-column flex-root">
    <!--begin::Login-->
    <div
      class="login login-4 d-flex flex-row-fluid"
      id="kt_login"
      :class="{
        'login-signin-on': this.state === 'signin',
        'login-signup-on': this.state === 'signup',
        'login-forgot-on': this.state === 'forgot'
      }"
    >
      <div
        class="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat"
        style="background-image: url('media/bg/bg-3.jpg');"
      >
        <div
          class="login-form text-center p-7 position-relative overflow-hidden"
        >
          <!--begin::Login Header-->
          <div class="d-flex flex-center mb-15">
            <a href="#">
              <img src="./logo-letter-13.png" class="max-h-75px" alt="" />
            </a>
          </div>
          <!--end::Login Header-->
          <!--begin::Login Sign in form-->
          <div class="login-signin">
            <div class="mb-20">
              <h3>Iniciar sesión</h3>
              <div class="text-muted font-weight-bold">
                Ingresa tus credenciales para obtener acceso
              </div>
            </div>
            <form
              id="kt_login_signin_form"
              class="form"
              @submit.stop.prevent="onSubmitLogin"
              novalidate="novalidate"
            >
              <div class="form-group mb-5">
                <input
                  class="form-control h-auto form-control-solid py-4 px-8"
                  type="text"
                  placeholder="Usuario"
                  name="username"
                  ref="username"
                  v-model="form.username"
                  autocomplete="off"
                />
              </div>
              <div class="input-group mb-5">
                <b-form-input
                  class="form-control h-auto form-control-solid py-4 px-8"
                  :type="passwordFieldType"
                  name="password"
                  ref="password"
                  v-model="form.password"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-append>
                  <b-button @click="switchVisibility">
                    <b-icon icon="eye" aria-hidden="true"></b-icon>
                  </b-button>
                </b-input-group-append>
              </div>
              <div class="form-group mb-5">
                <b-form-select
                  class="form-control h-auto form-control-solid py-4 px-8"
                  name="enterprise"
                  ref="enterprise"
                  :options="enterprise_list"
                  v-model="form.enterprise"
                  text-field="name"
                  value-field="id"
                />
              </div>
              <div
                class="form-group d-flex flex-wrap justify-content-between align-items-center"
              >
                <router-link
                  :to="{ name: 'recover_password' }"
                  id="kt_login_forgot"
                  class="text-muted text-hover-primary"
                >¿Olvidaste tu contraseña?
                </router-link>
              </div>
              <button
                id="kt_login_signin_submit"
                class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Iniciar sesión
              </button>
            </form>
            <div class="mt-10">
              <span class="opacity-70 mr-4"></span>
              <a
                href="javascript:;"
                id="kt_login_signup"
                class="text-muted text-hover-primary font-weight-bold"
              ></a
              >
            </div>
          </div>
          <!--end::Login Sign in form-->
        </div>
      </div>
    </div>
    <!--end::Login-->
  </div>
</template>

<!-- Load login custom page styles -->
<style>
@import "./login-2.css";
</style>

<script>
import { mapGetters, mapState } from "vuex";
import { LOGIN } from "@/core/services/store/auth.module";

import ApiService from "@/core/services/api.service";

export default {
  name: "login-1",
  data() {
    return {
      passwordFieldType: "password",
      enterprise_list: [],
      state: "signin",
      // Remove this dummy login info
      form: {
        username: "46552683",
        password: "123qweASDASDASD"
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    }
  },
  created() {
    this.getEnterprises();
  },
  mounted() {
    // Clean localStorage on enter page to avoid conflicts
    localStorage.clear();
    this.getEnterprises();
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },

    getEnterprises() {
      ApiService.get("api/enterprise").then(response => {
        this.enterprise_list = response.data;
        this.form.enterprise = response.data[0].id;
      });
    },

    onSubmitLogin() {
      let username = this.form.username;
      let password = this.form.password;
      let enterprise = this.form.enterprise;

      //Clear any previous saves
      localStorage.clear();

      // set spinner to submit button
      const submitButton = document.getElementById("kt_login_signin_submit");
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(LOGIN, { username, password, enterprise })
        // go to which page after successfully login
        .then(() => this.$router.push({ name: "dashboard" }))
        .catch(error => {
          console.log("error", error);
          this.$bvToast.toast("La contraseña es incorrecta", {
            title: `Error`,
            variant: "danger",
            solid: true
          });
        });

      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    }
  }
};
</script>
